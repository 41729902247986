import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var Env = {
  envType: 1,
  //1生产环境
  baseURL: process.env.VUE_APP_API,
  //接口地址
  baseDir: "",
  version: "v1.0.135" //2024年1月19日18:56:45
};

//只有在开发环境下，配置才会生效
if (process.env.NODE_ENV === 'development') {
  console.log("config文件#debug#🚀 ~ file: Env.js:7 ~ Env begin:", process.env.NODE_ENV, JSON.stringify(Env));
  // console.log(" process.env,", process.env);
  // console.log(" process.env.VUE_APP_API,", process.env.VUE_APP_API);
  // Env.envType = 2; //公司测试，
  //Env.envType = 3; //个人测试
  // Env.envType = 4; //楷德测试
  // Env.envType = 5; //内网穿透
  if (Env.envType == 2) {
    Env.baseURL = "http://ssl.ycxxkj.com/school_service/v2_release/api/public/index.php";
  }
  if (Env.envType == 3) {
    // Env.domain = process.env.VUE_APP_API;
    // Env.baseURL = 'http://local74.lzj/school_service/api/public/index.php' // test环境地址
    Env.baseURL = "http://kdschool/index.php";
  }
  if (Env.envType == 4) {
    Env.baseURL = "https://kft.ycxxkj.com/index.php";
  }
  if (Env.envType == 5) {
    Env.baseURL = "http://jctfec.natappfree.cc/school_service/v2_release/api/public/index.php";
  }
  console.log("#debug#🚀 ~ file: Env.js:31 ~ Env:", Env);
}
Env.baseDir = Env.baseURL.replace("/index.php", "");
export default Env;