import _toConsumableArray from "/www/wwwroot/kft.ycxxkj.com/admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
import routerCourse from "./routerCourse";
import routeSystem from './routeSystem';
import routeOrder from './routeOrder';
/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true,
      title: '首页'
    },
    component: _import('system/index')
  }].concat(_toConsumableArray(routeSystem), _toConsumableArray(routeOrder), [
  //订单相关页面路由
  {
    path: '/admin',
    name: 'admin',
    component: function component() {
      return import('@/pages/admin/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '管理员管理'
    }
  }, {
    path: '/admin/add',
    name: 'admin_add',
    component: function component() {
      return import('@/pages/admin/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增管理员'
    }
  }, {
    path: '/admin/edit',
    name: 'admin_edit',
    component: function component() {
      return import('@/pages/admin/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑管理员'
    }
  }, {
    path: '/admin_pwd',
    name: 'admin_pwd',
    component: function component() {
      return import('@/pages/admin/pwd');
    },
    meta: {
      auth: true,
      title: '修改密码'
    }
  }, {
    path: '/adminSetting',
    name: 'adminSetting',
    component: function component() {
      return import('@/pages/admin/setting');
    },
    meta: {
      auth: true,
      title: '重置密码'
    }
  }, {
    path: '/messageSetting',
    name: 'messageSetting',
    component: function component() {
      return import('@/pages/admin/message');
    },
    meta: {
      auth: true,
      title: '消息设置'
    }
  }, {
    path: '/admin/init',
    name: 'admin_init',
    component: function component() {
      return import('@/pages/admin/init');
    },
    meta: {
      auth: true,
      title: '导入初始数据'
    }
  }, {
    path: '/admin_role',
    name: 'admin_role',
    component: function component() {
      return import('@/pages/admin_role/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '角色管理'
    }
  }, {
    path: '/admin_role/add',
    name: 'admin_role_add',
    component: function component() {
      return import('@/pages/admin_role/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增角色'
    }
  }, {
    path: '/admin_role/edit',
    name: 'admin_role_edit',
    component: function component() {
      return import('@/pages/admin_role/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑角色'
    }
  }, {
    path: '/work_dept',
    name: 'work_dept',
    component: function component() {
      return import('@/pages/work_dept/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '部门管理'
    }
  }, {
    path: '/work_dept/add',
    name: 'work_dept_add',
    component: function component() {
      return import('@/pages/work_dept/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增部门'
    }
  }, {
    path: '/work_dept/edit',
    name: 'work_dept_edit',
    component: function component() {
      return import('@/pages/work_dept/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑部门'
    }
  }, {
    path: '/work_position',
    name: 'work_position',
    component: function component() {
      return import('@/pages/work_position/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '岗位管理'
    }
  }, {
    path: '/work_position/add',
    name: 'work_position_add',
    component: function component() {
      return import('@/pages/work_position/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增岗位'
    }
  }, {
    path: '/work_position/edit',
    name: 'work_position_edit',
    component: function component() {
      return import('@/pages/work_position/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑岗位'
    }
  }, {
    path: '/staff',
    name: 'staff',
    component: function component() {
      return import('@/pages/staff/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工管理'
    }
  }, {
    path: '/staff/add',
    name: 'staff_add',
    component: function component() {
      return import('@/pages/staff/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增员工'
    }
  }, {
    path: '/staff/edit',
    name: 'staff_edit',
    component: function component() {
      return import('@/pages/staff/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑员工'
    }
  }, {
    path: '/teacher',
    name: 'teacher',
    component: function component() {
      return import('@/pages/teacher/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师管理'
    }
  }, {
    path: '/teacher/add',
    name: 'teacher_add',
    component: function component() {
      return import('@/pages/teacher/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增教师'
    }
  }, {
    path: '/teacher/edit',
    name: 'teacher_edit',
    component: function component() {
      return import('@/pages/teacher/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑教师'
    }
  }, {
    path: '/parents',
    name: 'parents',
    component: function component() {
      return import('@/pages/parents/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '家长管理'
    }
  }, {
    path: '/parents/add',
    name: 'parents_add',
    component: function component() {
      return import('@/pages/parents/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增家长'
    }
  }, {
    path: '/parents/edit',
    name: 'parents_edit',
    component: function component() {
      return import('@/pages/parents/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑家长'
    }
  }, {
    path: '/student',
    name: 'student',
    component: function component() {
      return import('@/pages/student/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生管理'
    }
  }, {
    path: '/student/add',
    name: 'student_add',
    component: function component() {
      return import('@/pages/student/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增学生'
    }
  }, {
    path: '/student/edit',
    name: 'student_edit',
    component: function component() {
      return import('@/pages/student/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑学生'
    }
  }, {
    path: '/black_list',
    name: 'black_list',
    component: function component() {
      return import('@/pages/black_list/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生黑名单管理'
    }
  }, {
    path: '/black_list/add',
    name: 'black_list_add',
    component: function component() {
      return import('@/pages/black_list/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增黑名单'
    }
  }, {
    path: '/black_list/edit',
    name: 'black_list_edit',
    component: function component() {
      return import('@/pages/black_list/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑黑名单'
    }
  }, {
    path: '/student/coupon/log',
    name: 'student_coupon_log',
    component: function component() {
      return import('@/pages/student/coupon_log');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生的优惠券领取记录'
    }
  }], _toConsumableArray(routerCourse), [
  //课程相关的路由

  {
    path: '/coach_assess_1',
    name: 'coach_assess_1',
    component: function component() {
      return import('@/pages/coach_assess/index_1');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生评价'
    }
  }, {
    path: '/coach_assess_2',
    name: 'coach_assess_2',
    component: function component() {
      return import('@/pages/coach_assess/index_2');
    },
    meta: {
      auth: true,
      cache: true,
      title: '家长评价'
    }
  }, {
    path: '/coach_assess/add',
    name: 'coach_assess_add',
    component: function component() {
      return import('@/pages/coach_assess/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '评价添加'
    }
  }, {
    path: '/coach_assess/edit',
    name: 'coach_assess_edit',
    component: function component() {
      return import('@/pages/coach_assess/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '管理评价'
    }
  }, {
    path: '/coach_check',
    name: 'coach_check',
    component: function component() {
      return import('@/pages/coach_check/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排课管理'
    }
  }, {
    path: '/coach_check/edit',
    name: 'coach_check_edit',
    component: function component() {
      return import('@/pages/coach_check/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '进行排课'
    }
  }, {
    path: '/coach_check_search',
    name: 'coach_check_search',
    component: function component() {
      return import('@/pages/coach_check/check');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤记录'
    }
  }, {
    path: '/coach_check/detail',
    name: 'coach_check_detail',
    component: function component() {
      return import('@/pages/coach_check/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤详情'
    }
  }, {
    path: '/course_show',
    name: 'course_show',
    component: function component() {
      return import('@/pages/course_show/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课堂风采管理'
    }
  }, {
    path: '/course_show/add',
    name: 'course_show_add',
    component: function component() {
      return import('@/pages/course_show/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增课堂风采'
    }
  }, {
    path: '/course_show/edit',
    name: 'course_show_edit',
    component: function component() {
      return import('@/pages/course_show/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑课堂风采'
    }
  }, {
    path: '/coach_adjust',
    name: 'coach_adjust',
    component: function component() {
      return import('@/pages/coach_adjust/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '调课管理'
    }
  }, {
    path: '/coach_adjust/add',
    name: 'coach_adjust_add',
    component: function component() {
      return import('@/pages/coach_adjust/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增调课'
    }
  }, {
    path: '/coach_adjust/edit',
    name: 'coach_adjust_edit',
    component: function component() {
      return import('@/pages/coach_adjust/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑调课'
    }
  }, {
    path: '/trust_assess',
    name: 'trust_assess',
    component: function component() {
      return import('@/pages/trust_assess/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '托管评价'
    }
  }, {
    path: '/trust_assess/add',
    name: 'trust_assess_add',
    component: function component() {
      return import('@/pages/trust_assess/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加托管评价'
    }
  }, {
    path: '/trust_assess/edit',
    name: 'trust_assess_edit',
    component: function component() {
      return import('@/pages/trust_assess/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '管理托管评价'
    }
  }, {
    path: '/coach_check_pay',
    name: 'coach_check_pay',
    component: function component() {
      return import('@/pages/coach_check_pay/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课堂考勤统计'
    }
  }, {
    path: '/config_system',
    name: 'config_system',
    component: function component() {
      return import('@/pages/config/system');
    },
    meta: {
      auth: true,
      title: '系统信息设置'
    }
  }, {
    path: '/config_wechat',
    name: 'config_wechat',
    component: function component() {
      return import('@/pages/config/wechat');
    },
    meta: {
      auth: true,
      title: '微信设置'
    }
  }, {
    path: '/config_index',
    name: 'config_index',
    component: function component() {
      return import('@/pages/config/index');
    },
    meta: {
      auth: true,
      title: '公司简介'
    }
  }, {
    path: '/config_dev',
    name: 'config_dev',
    component: function component() {
      return import('@/pages/config/dev');
    },
    meta: {
      auth: true,
      title: '开发配置'
    }
  }, {
    path: '/admin_log',
    name: 'admin_log',
    component: function component() {
      return import('@/pages/admin_log/index');
    },
    meta: {
      auth: true,
      title: '系统日志',
      cache: true
    }
  },
  // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  },
  // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  },
  // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }, {
    path: '/account_set',
    name: 'account_set',
    component: function component() {
      return import('@/pages/setting/account_set');
    },
    meta: {
      auth: true,
      title: '账套设置'
    }
  },
  ///voucher/attachment
  {
    path: '/operation_log',
    name: 'operation_log',
    component: function component() {
      return import('@/pages/operation_log/index');
    },
    meta: {
      auth: true,
      title: '操作日志'
    }
  },
  //本地新增路由
  {
    path: '/area',
    name: 'area',
    component: function component() {
      return import('@/pages/area/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '区域管理'
    }
  }, {
    path: '/area/add',
    name: 'area_add',
    component: function component() {
      return import('@/pages/area/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加区域'
    }
  }, {
    path: '/area/edit',
    name: 'area_edit',
    component: function component() {
      return import('@/pages/area/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑区域'
    }
  },
  //本地新增路由
  {
    path: '/school',
    name: 'school',
    component: function component() {
      return import('@/pages/school/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学校管理'
    }
  }, {
    path: '/school/add',
    name: 'school_add',
    component: function component() {
      return import('@/pages/school/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加学校'
    }
  }, {
    path: '/school/edit',
    name: 'school_edit',
    component: function component() {
      return import('@/pages/school/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑学校'
    }
  }, {
    path: '/school_district',
    name: 'school_district',
    component: function component() {
      return import('@/pages/school_district/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '校区管理'
    }
  }, {
    path: '/school_district/add',
    name: 'school_district_add',
    component: function component() {
      return import('@/pages/school_district/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加校区'
    }
  }, {
    path: '/school_district/edit',
    name: 'school_district_edit',
    component: function component() {
      return import('@/pages/school_district/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑校区'
    }
  }, {
    path: '/grade',
    name: 'grade',
    component: function component() {
      return import('@/pages/grade/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '年级管理'
    }
  }, {
    path: '/grade/add',
    name: 'grade_add',
    component: function component() {
      return import('@/pages/grade/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '年级添加'
    }
  }, {
    path: '/grade/edit',
    name: 'grade_edit',
    component: function component() {
      return import('@/pages/grade/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '年级编辑'
    }
  }, {
    path: '/class',
    name: 'class',
    component: function component() {
      return import('@/pages/class/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '班级管理'
    }
  }, {
    path: '/class/add',
    name: 'class_add',
    component: function component() {
      return import('@/pages/class/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '班级添加'
    }
  }, {
    path: '/class/edit',
    name: 'class_edit',
    component: function component() {
      return import('@/pages/class/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '班级编辑'
    }
  }, {
    path: '/teach_building',
    name: 'teach_building',
    component: function component() {
      return import('@/pages/teach_building/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教学楼管理'
    }
  }, {
    path: '/teach_building/add',
    name: 'teach_building_add',
    component: function component() {
      return import('@/pages/teach_building/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教学楼添加'
    }
  }, {
    path: '/teach_building/edit',
    name: 'teach_building_edit',
    component: function component() {
      return import('@/pages/teach_building/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教学楼编辑'
    }
  }, {
    path: '/room',
    name: 'room',
    component: function component() {
      return import('@/pages/room/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教室管理'
    }
  }, {
    path: '/room/add',
    name: 'room_add',
    component: function component() {
      return import('@/pages/room/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教室添加'
    }
  }, {
    path: '/room/edit',
    name: 'room_edit',
    component: function component() {
      return import('@/pages/room/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教室编辑'
    }
  }, {
    path: '/dorm',
    name: 'dorm',
    component: function component() {
      return import('@/pages/dorm/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '楼栋管理'
    }
  }, {
    path: '/dorm/add',
    name: 'dorm_add',
    component: function component() {
      return import('@/pages/dorm/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '楼栋添加'
    }
  }, {
    path: '/dorm/edit',
    name: 'dorm_edit',
    component: function component() {
      return import('@/pages/dorm/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '楼栋编辑'
    }
  }, {
    path: '/dorm_room',
    name: 'dorm_room',
    component: function component() {
      return import('@/pages/dorm_room/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '宿舍管理'
    }
  }, {
    path: '/dorm_room/add',
    name: 'dorm_room_add',
    component: function component() {
      return import('@/pages/dorm_room/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '宿舍添加'
    }
  }, {
    path: '/dorm_room/edit',
    name: 'dorm_room_edit',
    component: function component() {
      return import('@/pages/dorm_room/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '宿舍编辑'
    }
  }, {
    path: '/dorm_bed',
    name: 'dorm_bed',
    component: function component() {
      return import('@/pages/dorm_bed/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '床位管理'
    }
  }, {
    path: '/dorm_bed/add',
    name: 'dorm_bed_add',
    component: function component() {
      return import('@/pages/dorm_bed/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '床位添加'
    }
  }, {
    path: '/dorm_bed/edit',
    name: 'dorm_bed_edit',
    component: function component() {
      return import('@/pages/dorm_bed/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '床位编辑'
    }
  }, {
    path: '/organ_type',
    name: 'organ_type',
    component: function component() {
      return import('@/pages/organ_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构类型管理'
    }
  }, {
    path: '/organ_type/add',
    name: 'organ_type_add',
    component: function component() {
      return import('@/pages/organ_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构类型添加'
    }
  }, {
    path: '/organ_type/edit',
    name: 'organ_type_edit',
    component: function component() {
      return import('@/pages/organ_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构类型编辑'
    }
  }, {
    path: '/organ',
    name: 'organ',
    component: function component() {
      return import('@/pages/organ/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构管理'
    }
  }, {
    path: '/organ_account',
    name: 'organ_account',
    component: function component() {
      return import('@/pages/organ_account/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构账号'
    }
  }, {
    path: '/organ_account/edit',
    name: 'organ_account_edit',
    component: function component() {
      return import('@/pages/organ_account/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增/编辑机构账号'
    }
  }, {
    path: '/organ/add',
    name: 'organ_add',
    component: function component() {
      return import('@/pages/organ/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构添加'
    }
  }, {
    path: '/organ/edit',
    name: 'organ_edit',
    component: function component() {
      return import('@/pages/organ/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构编辑'
    }
  }, {
    path: '/organ/organ_teacher',
    name: 'organ_teacher',
    component: function component() {
      return import('@/pages/organ/organ_teacher');
    },
    meta: {
      auth: true,
      cache: true,
      title: '机构关联教师'
    }
  }, {
    path: '/certification_student/:type',
    name: 'certification_student',
    component: function component() {
      return import('@/pages/certification/student');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生认证'
    }
  }, {
    path: '/certification_parent/:type',
    name: 'certification_parent',
    component: function component() {
      return import('@/pages/certification/teacher');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师认证'
    }
  }, {
    path: '/certification_staff/:type',
    name: 'certification_staff',
    component: function component() {
      return import('@/pages/certification/staff');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工认证'
    }
  }, {
    path: '/sensitive_log',
    name: 'sensitive_log',
    component: function component() {
      return import('@/pages/sensitive_log/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '敏感日志'
    }
  }, {
    path: '/protocol_index/:type',
    name: 'protocol_index',
    component: function component() {
      return import('@/pages/protocol/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '协议管理'
    }
  }, {
    path: '/protocol_login',
    name: 'protocol_login',
    component: function component() {
      return import('@/pages/protocol/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '登录协议'
    }
  }, {
    path: '/service/index',
    name: 'service_index',
    component: function component() {
      return import('@/pages/service/index');
    },
    meta: {
      auth: true,
      title: '托管服务',
      cache: true
    }
  }, {
    path: '/service/add',
    name: 'service_add',
    component: function component() {
      return import('@/pages/service/add');
    },
    meta: {
      auth: true,
      title: '托管服务新增',
      cache: true
    }
  }, {
    path: '/service/save',
    name: 'service_save',
    component: function component() {
      return import('@/pages/service/save');
    },
    meta: {
      auth: true,
      title: '托管服务编辑',
      cache: true
    }
  }, {
    path: '/service/test',
    name: 'service_test',
    component: function component() {
      return import('@/pages/service/test');
    },
    meta: {
      auth: true,
      title: '测试',
      cache: true
    }
  }, {
    path: '/service_type/index',
    name: 'service_type',
    component: function component() {
      return import('@/pages/service_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '托管课程分类'
    }
  }, {
    path: '/service_type/add',
    name: 'service_type_add',
    component: function component() {
      return import('@/pages/service_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '托管课程分类添加'
    }
  }, {
    path: '/service_type/edit',
    name: 'service_type_edit',
    component: function component() {
      return import('@/pages/service_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '托管课程分类编辑'
    }
  }, {
    path: '/test/quill_editor',
    name: '/test/quill_editor',
    component: function component() {
      return import('@/pages/test/quill_editor');
    },
    meta: {
      auth: true,
      title: '测试富文本编辑器',
      cache: true
    }
  }, {
    path: '/meal/index',
    name: 'meal_index',
    component: function component() {
      return import('@/pages/meal/index');
    },
    meta: {
      auth: true,
      title: '套餐管理',
      cache: true
    }
  }, {
    path: '/meal/add',
    name: 'meal_add',
    component: function component() {
      return import('@/pages/meal/save');
    },
    meta: {
      auth: true,
      title: '套餐新增',
      cache: true
    }
  }, {
    path: '/meal/save',
    name: 'meal_save',
    component: function component() {
      return import('@/pages/meal/save');
    },
    meta: {
      auth: true,
      title: '套餐编辑',
      cache: true
    }
  }, {
    path: '/coupon/index',
    name: 'coupon_index',
    component: function component() {
      return import('@/pages/coupon/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '优惠券管理'
    }
  }, {
    path: '/coupon/add',
    name: 'coupon_add',
    component: function component() {
      return import('@/pages/coupon/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '优惠券添加'
    }
  }, {
    path: '/coupon/edit',
    name: 'coupon_edit',
    component: function component() {
      return import('@/pages/coupon/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '优惠券编辑'
    }
  }, {
    path: '/coupon/log',
    name: 'coupon_log',
    component: function component() {
      return import('@/pages/coupon/log');
    },
    meta: {
      auth: true,
      cache: true,
      title: '优惠券发放记录'
    }
  }, {
    path: '/coupon_log/add',
    name: 'coupon_log_add',
    component: function component() {
      return import('@/pages/coupon/add_log');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加优惠券发放记录'
    }
  }, {
    path: '/menu/index',
    name: 'menu_index',
    component: function component() {
      return import('@/pages/menu/index');
    },
    meta: {
      auth: true,
      title: '菜谱管理',
      cache: true
    }
  }, {
    path: '/menu/add',
    name: 'menu_add',
    component: function component() {
      return import('@/pages/menu/add');
    },
    meta: {
      auth: true,
      title: '菜谱新增',
      cache: true
    }
  }, {
    path: '/menu/save',
    name: 'menu_save',
    component: function component() {
      return import('@/pages/menu/save');
    },
    meta: {
      auth: true,
      title: '菜谱编辑',
      cache: true
    }
  }, {
    path: '/meal_order/index',
    name: 'meal_order_index',
    component: function component() {
      return import('@/pages/meal_order/index');
    },
    meta: {
      auth: true,
      title: '子订单',
      cache: true
    }
  }, {
    path: '/meal_order/takes',
    name: 'meal_order_takes',
    component: function component() {
      return import('@/pages/meal_order/takes');
    },
    meta: {
      auth: true,
      title: '核销记录',
      cache: true
    }
  }, {
    path: '/meal_order/tracing_report',
    name: 'meal_order_tracing_report',
    component: function component() {
      return import('@/pages/meal_order/tracing_report');
    },
    meta: {
      auth: true,
      title: '溯源报告',
      cache: true
    }
  }, {
    path: '/meal_order/tracing_search',
    name: 'meal_order_tracing_search',
    component: function component() {
      return import('@/pages/meal_order/tracing_search');
    },
    meta: {
      auth: true,
      title: '溯源查询',
      cache: true
    }
  }, {
    path: '/meal_order/add',
    name: 'meal_order_add',
    component: function component() {
      return import('@/pages/meal_order/add');
    },
    meta: {
      auth: true,
      title: '订餐新增',
      cache: true
    }
  }, {
    path: '/meal_order/plan',
    name: 'meal_order_plan',
    component: function component() {
      return import('@/pages/meal_order/plan');
    },
    meta: {
      auth: true,
      title: '生产计划',
      cache: true
    }
  }, {
    path: '/meal_order/divide',
    name: 'meal_order_divide',
    component: function component() {
      return import('@/pages/meal_order/divide');
    },
    meta: {
      auth: true,
      title: '分餐计划',
      cache: true
    }
  }, {
    path: '/meal_parent_order/index',
    name: 'meal_parent_order_index',
    component: function component() {
      return import('@/pages/meal_parent_order/index');
    },
    meta: {
      auth: true,
      title: '订单',
      cache: true
    }
  }, {
    path: '/meal_parent_order/add',
    name: 'meal_parent_order_add',
    component: function component() {
      return import('@/pages/meal_parent_order/add');
    },
    meta: {
      auth: true,
      title: '新增订单',
      cache: true
    }
  }, {
    path: '/meal_parent_order/detail',
    name: 'meal_parent_order_detail',
    component: function component() {
      return import('@/pages/meal_parent_order/detail');
    },
    meta: {
      auth: true,
      title: '订单详情',
      cache: true
    }
  }, {
    path: '/raw_purchase/index',
    name: 'raw_purchase_index',
    component: function component() {
      return import('@/pages/raw_purchase/index');
    },
    meta: {
      auth: true,
      title: '采购计划',
      cache: true
    }
  }, {
    path: '/dish_sample/index',
    name: 'dish_sample_index',
    component: function component() {
      return import('@/pages/dish_sample/index');
    },
    meta: {
      auth: true,
      title: '留样计划',
      cache: true
    }
  }, {
    path: '/protocol/add',
    name: 'protocol_add',
    component: function component() {
      return import('@/pages/protocol/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '协议添加'
    }
  }, {
    path: '/protocol/edit',
    name: 'protocol_edit',
    component: function component() {
      return import('@/pages/protocol/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '协议编辑'
    }
  }, {
    path: '/course_type',
    name: 'course_type',
    component: function component() {
      return import('@/pages/course_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程分类'
    }
  }, {
    path: '/course_type/add',
    name: 'course_type_add',
    component: function component() {
      return import('@/pages/course_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程分类添加'
    }
  }, {
    path: '/course_type/edit',
    name: 'course_type_edit',
    component: function component() {
      return import('@/pages/course_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程分类编辑'
    }
  }, {
    path: '/canteen',
    name: 'canteen',
    component: function component() {
      return import('@/pages/canteen/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '饭堂维护'
    }
  }, {
    path: '/canteen/add',
    name: 'canteen_add',
    component: function component() {
      return import('@/pages/canteen/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '饭堂添加'
    }
  }, {
    path: '/canteen/edit',
    name: 'canteen_edit',
    component: function component() {
      return import('@/pages/canteen/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '饭堂编辑'
    }
  }, {
    path: '/meal_times',
    name: 'meal_times',
    component: function component() {
      return import('@/pages/meal_times/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '餐次管理'
    }
  }, {
    path: '/meal_times/add',
    name: 'meal_times_add',
    component: function component() {
      return import('@/pages/meal_times/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '餐次添加'
    }
  }, {
    path: '/meal_times/edit',
    name: 'meal_times_edit',
    component: function component() {
      return import('@/pages/meal_times/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '餐次编辑'
    }
  }, {
    path: '/raw_type',
    name: 'raw_type',
    component: function component() {
      return import('@/pages/raw_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料分类'
    }
  }, {
    path: '/raw_type/add',
    name: 'raw_type_add',
    component: function component() {
      return import('@/pages/raw_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料分类添加'
    }
  }, {
    path: '/raw_type/edit',
    name: 'raw_type_edit',
    component: function component() {
      return import('@/pages/raw_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料分类编辑'
    }
  }, {
    path: '/raw',
    name: 'raw',
    component: function component() {
      return import('@/pages/raw/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料管理'
    }
  }, {
    path: '/raw/add',
    name: 'raw_add',
    component: function component() {
      return import('@/pages/raw/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料添加'
    }
  }, {
    path: '/raw/edit',
    name: 'raw_edit',
    component: function component() {
      return import('@/pages/raw/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料编辑'
    }
  }, {
    path: '/dishes_type',
    name: 'dishes_type',
    component: function component() {
      return import('@/pages/dishes_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品分类'
    }
  }, {
    path: '/dishes_type/add',
    name: 'dishes_type_add',
    component: function component() {
      return import('@/pages/dishes_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品分类添加'
    }
  }, {
    path: '/dishes_type/edit',
    name: 'dishes_type_edit',
    component: function component() {
      return import('@/pages/dishes_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品分类编辑'
    }
  }, {
    path: '/dishes',
    name: 'dishes',
    component: function component() {
      return import('@/pages/dishes/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品信息'
    }
  }, {
    path: '/dishes/add',
    name: 'dishes_add',
    component: function component() {
      return import('@/pages/dishes/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品添加'
    }
  }, {
    path: '/dishes/edit',
    name: 'dishes_edit',
    component: function component() {
      return import('@/pages/dishes/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '菜品编辑'
    }
  }, {
    path: '/article_type',
    name: 'article_type',
    component: function component() {
      return import('@/pages/article_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章分类'
    }
  }, {
    path: '/article_type/add',
    name: 'article_type_add',
    component: function component() {
      return import('@/pages/article_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章分类添加'
    }
  }, {
    path: '/article_type/edit',
    name: 'article_type_edit',
    component: function component() {
      return import('@/pages/article_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章分类编辑'
    }
  }, {
    path: '/article',
    name: 'article_list',
    component: function component() {
      return import('@/pages/article/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章管理'
    }
  }, {
    path: '/article/add',
    name: 'article_add',
    component: function component() {
      return import('@/pages/article/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章添加'
    }
  }, {
    path: '/article/edit',
    name: 'article_edit',
    component: function component() {
      return import('@/pages/article/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章编辑'
    }
  }, {
    path: '/school/study',
    name: 'school_study',
    component: function component() {
      return import('@/pages/school/study');
    },
    meta: {
      auth: true,
      cache: true,
      title: '校历设置'
    }
  }, {
    path: '/grade_change',
    name: 'grade_change',
    component: function component() {
      return import('@/pages/grade_change/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '切换学年'
    }
  }, {
    path: '/bed_allocation',
    name: 'bed_allocation',
    component: function component() {
      return import('@/pages/bed_allocation/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '床位分配'
    }
  }, {
    path: '/bed_allocation/edit',
    name: 'bed_allocation_edit',
    component: function component() {
      return import('@/pages/bed_allocation/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑床位分配'
    }
  }, {
    path: '/bed_allocation/edit_bed',
    name: 'bed_allocation_edit_bed',
    component: function component() {
      return import('@/pages/bed_allocation/editBed');
    },
    meta: {
      auth: true,
      cache: true,
      title: '调整床位'
    }
  }, {
    path: '/trust_order',
    name: 'trust_order',
    component: function component() {
      return import('@/pages/trust_order/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤记录'
    }
  }, {
    path: '/trust_order/edit',
    name: 'trust_order_edit',
    component: function component() {
      return import('@/pages/trust_order/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤编辑'
    }
  }, {
    path: '/trust_statistics',
    name: 'trust_statistics',
    component: function component() {
      return import('@/pages/trust_statistics/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤统计'
    }
  }, {
    path: '/trust_statistics_details',
    name: 'trust_statistics_details',
    component: function component() {
      return import('@/pages/trust_statistics/details');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤统计详情'
    }
  }, {
    path: '/complain',
    name: 'complain',
    component: function component() {
      return import('@/pages/complain/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '投诉服务'
    }
  }, {
    path: '/complain/edit',
    name: 'complain_edit',
    component: function component() {
      return import('@/pages/complain/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '投诉编辑'
    }
  }, {
    path: '/leave/student',
    name: 'leave_student',
    component: function component() {
      return import('@/pages/leave/student');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生请假'
    }
  }, {
    path: '/leave/teacher',
    name: 'leave_teacher',
    component: function component() {
      return import('@/pages/leave/teacher');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师请假'
    }
  }, {
    path: '/leave/staff',
    name: 'leave_staff',
    component: function component() {
      return import('@/pages/leave/staff');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工请假'
    }
  }, {
    path: '/leave/edit',
    name: 'leave_edit',
    component: function component() {
      return import('@/pages/leave/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '请假审核'
    }
  }, {
    path: '/order_flows',
    name: 'order_flows',
    component: function component() {
      return import('@/pages/order_flow/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '资金流水'
    }
  }, {
    path: '/order_flows/details',
    name: 'order_flows_details',
    component: function component() {
      return import('@/pages/order_flow/details');
    },
    meta: {
      auth: true,
      cache: true,
      title: '流水详情'
    }
  }, {
    path: '/order_flows/icbc',
    name: 'order_flows_icbc',
    component: function component() {
      return import('@/pages/order_flow/icbc');
    },
    meta: {
      auth: true,
      cache: true,
      title: '工行流水'
    }
  }, {
    path: '/face/student/:data_type',
    name: 'face_student',
    component: function component() {
      return import('@/pages/face/student');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生人脸库'
    }
  }, {
    path: '/face/teacher/:data_type',
    name: 'face_teacher',
    component: function component() {
      return import('@/pages/face/teacher');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师人脸库'
    }
  }, {
    path: '/face/staff/:data_type',
    name: 'face_staff',
    component: function component() {
      return import('@/pages/face/staff');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工人脸库'
    }
  }, {
    path: '/face/add',
    name: 'face_add',
    component: function component() {
      return import('@/pages/face/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增人脸库'
    }
  }, {
    path: '/face/edit',
    name: 'face_edit',
    component: function component() {
      return import('@/pages/face/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '修改人脸库'
    }
  }, {
    path: '/semester',
    name: 'semester',
    component: function component() {
      return import('@/pages/semester/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学期设置'
    }
  }, {
    path: '/semester/add',
    name: 'semester_add',
    component: function component() {
      return import('@/pages/semester/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学期添加'
    }
  }, {
    path: '/semester/edit',
    name: 'semester_edit',
    component: function component() {
      return import('@/pages/semester/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学期编辑'
    }
  }, {
    path: '/semester_refund',
    name: 'semester_refund',
    component: function component() {
      return import('@/pages/semester_refund/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '退款管理'
    }
  }, {
    path: '/semester_refund_pass',
    name: 'semester_refund_pass',
    component: function component() {
      return import('@/pages/semester_refund/semester_refund_pass');
    },
    meta: {
      auth: true,
      cache: true,
      title: '导入审核'
    }
  }, {
    path: '/semester_refund/add',
    name: 'semester_refund_add',
    component: function component() {
      return import('@/pages/semester_refund/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '选择学生'
    }
  }, {
    path: '/semester_refund/add_refund',
    name: 'semester_refund_add_refund',
    component: function component() {
      return import('@/pages/semester_refund/add_refund');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加退款'
    }
  }, {
    path: '/semester_refund/edit',
    name: 'semester_refund_edit_refund',
    component: function component() {
      return import('@/pages/semester_refund/edit_refund');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑退款'
    }
  }, {
    path: '/banner/index',
    name: 'banner',
    component: function component() {
      return import('@/pages/banner/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: 'Banner管理'
    }
  }, {
    path: '/banner/add',
    name: 'banner_add',
    component: function component() {
      return import('@/pages/banner/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: 'Banner添加'
    }
  }, {
    path: '/banner/edit',
    name: 'banner_edit',
    component: function component() {
      return import('@/pages/banner/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: 'Banner编辑'
    }
  }, {
    path: '/financial_statement/course',
    name: 'financial_statement_course',
    component: function component() {
      return import('@/pages/financial_statement/course');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程财务报表'
    }
  }, {
    path: '/financial_statement/service',
    name: 'financial_statement_service',
    component: function component() {
      return import('@/pages/financial_statement/service');
    },
    meta: {
      auth: true,
      cache: true,
      title: '托管财务报表'
    }
  }, {
    path: '/financial_statement/financial_profiles',
    name: 'financial_profiles',
    component: function component() {
      return import('@/pages/financial_statement/financial_profiles');
    },
    meta: {
      auth: true,
      cache: true,
      title: '财务概况报表'
    }
  }, {
    path: '/financial_statement/meal',
    name: 'financial_statement_meal',
    component: function component() {
      return import('@/pages/financial_statement/meal');
    },
    meta: {
      auth: true,
      cache: true,
      title: '订餐财务报表'
    }
  }, {
    path: '/post_app',
    name: 'post_app',
    component: function component() {
      return import('@/pages/post_app/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '消费机app'
    }
  }, {
    path: '/student_monitor/index',
    name: 'student_monitor',
    component: function component() {
      return import('@/pages/student_monitor/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察'
    }
  }, {
    path: '/student_monitor/add',
    name: 'student_monitor_add',
    component: function component() {
      return import('@/pages/student_monitor/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察添加'
    }
  }, {
    path: '/student_monitor/edit',
    name: 'student_monitor_edit',
    component: function component() {
      return import('@/pages/student_monitor/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察编辑'
    }
  }, {
    path: '/student_monitor/detail',
    name: 'student_monitor_detail',
    component: function component() {
      return import('@/pages/student_monitor/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察详情'
    }
  }, {
    path: '/student_monitor_type/index',
    name: 'student_monitor_type',
    component: function component() {
      return import('@/pages/student_monitor_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察分类'
    }
  }, {
    path: '/student_monitor_type/add',
    name: 'student_monitor_type_add',
    component: function component() {
      return import('@/pages/student_monitor_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察分类添加'
    }
  }, {
    path: '/student_monitor_type/edit',
    name: 'student_monitor_type_edit',
    component: function component() {
      return import('@/pages/student_monitor_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察分类编辑'
    }
  }, {
    path: '/student_monitor_question/index',
    name: 'student_monitor_question',
    component: function component() {
      return import('@/pages/student_monitor_question/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察问题'
    }
  }, {
    path: '/student_monitor_question/add',
    name: 'student_monitor_question_add',
    component: function component() {
      return import('@/pages/student_monitor_question/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察问题添加'
    }
  }, {
    path: '/student_monitor_question/edit',
    name: 'student_monitor_question_edit',
    component: function component() {
      return import('@/pages/student_monitor_question/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '学生监察问题编辑'
    }
  }, {
    path: '/teacher_monitor/index',
    name: 'teacher_monitor',
    component: function component() {
      return import('@/pages/teacher_monitor/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师监察'
    }
  }, {
    path: '/teacher_monitor/add',
    name: 'teacher_monitor_add',
    component: function component() {
      return import('@/pages/teacher_monitor/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师监察添加'
    }
  }, {
    path: '/teacher_monitor/edit',
    name: 'teacher_monitor_edit',
    component: function component() {
      return import('@/pages/teacher_monitor/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师监察编辑'
    }
  }, {
    path: '/teacher_monitor/detail',
    name: 'teacher_monitor_detail',
    component: function component() {
      return import('@/pages/teacher_monitor/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师监察详情'
    }
  }, {
    path: '/staff_monitor/index',
    name: 'staff_monitor',
    component: function component() {
      return import('@/pages/staff_monitor/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工监察'
    }
  }, {
    path: '/staff_monitor/add',
    name: 'staff_monitor_add',
    component: function component() {
      return import('@/pages/staff_monitor/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工监察添加'
    }
  }, {
    path: '/staff_monitor/edit',
    name: 'staff_monitor_edit',
    component: function component() {
      return import('@/pages/staff_monitor/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工监察编辑'
    }
  }, {
    path: '/staff_monitor/detail',
    name: 'staff_monitor_detail',
    component: function component() {
      return import('@/pages/staff_monitor/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '员工监察详情'
    }
  }, {
    path: '/work_notice',
    name: 'work_notice',
    component: function component() {
      return import('@/pages/work_notice/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '工作通知'
    }
  }, {
    path: '/work_notice/add',
    name: 'work_notice_add',
    component: function component() {
      return import('@/pages/work_notice/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师员工工作通知添加'
    }
  }, {
    path: '/work_notice/addPar',
    name: 'work_notice_addPar',
    component: function component() {
      return import('@/pages/work_notice/addPar');
    },
    meta: {
      auth: true,
      cache: true,
      title: '家长工作通知添加'
    }
  }, {
    path: '/work_notice/edit',
    name: 'work_notice_edit',
    component: function component() {
      return import('@/pages/work_notice/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '教师员工工作通知编辑'
    }
  }, {
    path: '/work_notice/editPar',
    name: 'work_notice_editPar',
    component: function component() {
      return import('@/pages/work_notice/editPar');
    },
    meta: {
      auth: true,
      cache: true,
      title: '家长工作通知编辑'
    }
  }, {
    path: '/staff_check_status',
    name: 'staff_check_status',
    component: function component() {
      return import('@/pages/staff_check_status/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤状态'
    }
  }, {
    path: '/staff_check_status/add',
    name: 'staff_check_status_add',
    component: function component() {
      return import('@/pages/staff_check_status/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤状态添加'
    }
  }, {
    path: '/staff_check_status/edit',
    name: 'staff_check_status_edit',
    component: function component() {
      return import('@/pages/staff_check_status/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤状态编辑'
    }
  }, {
    path: '/staff_check',
    name: 'staff_check',
    component: function component() {
      return import('@/pages/staff_check/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤管理'
    }
  }, {
    path: '/staff_check/add',
    name: 'staff_check_add',
    component: function component() {
      return import('@/pages/staff_check/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤添加'
    }
  }, {
    path: '/staff_check/edit',
    name: 'staff_check_edit',
    component: function component() {
      return import('@/pages/staff_check/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤编辑'
    }
  }, {
    path: '/urgent_rule',
    name: 'urgent_rule',
    component: function component() {
      return import('@/pages/urgent_rule/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '规章制度'
    }
  }, {
    path: '/urgent_rule/add',
    name: 'urgent_rule_add',
    component: function component() {
      return import('@/pages/urgent_rule/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '规章制度添加'
    }
  }, {
    path: '/urgent_rule/edit',
    name: 'urgent_rule_edit',
    component: function component() {
      return import('@/pages/urgent_rule/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '规章制度编辑'
    }
  }, {
    path: '/article_emergency',
    name: 'article_emergency',
    component: function component() {
      return import('@/pages/article_emergency/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '应急管理文章'
    }
  }, {
    path: '/article_emergency/add',
    name: 'article_emergency_add',
    component: function component() {
      return import('@/pages/article_emergency/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '应急管理文章添加'
    }
  }, {
    path: '/article_emergency/edit',
    name: 'article_emergency_edit',
    component: function component() {
      return import('@/pages/article_emergency/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '应急管理文章编辑'
    }
  }, {
    path: '/article_regulation',
    name: 'article_regulation',
    component: function component() {
      return import('@/pages/article_regulation/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '制度文章'
    }
  }, {
    path: '/article_regulation/add',
    name: 'article_regulation_add',
    component: function component() {
      return import('@/pages/article_regulation/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '制度文章添加'
    }
  }, {
    path: '/article_regulation/edit',
    name: 'article_regulation_edit',
    component: function component() {
      return import('@/pages/article_regulation/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '制度文章编辑'
    }
  }, {
    path: '/article_assess',
    name: 'article_assess',
    component: function component() {
      return import('@/pages/article_assess/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章点评'
    }
  }, {
    path: '/article_assess/add',
    name: 'article_assess_add',
    component: function component() {
      return import('@/pages/article_assess/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章点评添加'
    }
  }, {
    path: '/article_assess/edit',
    name: 'article_assess_edit',
    component: function component() {
      return import('@/pages/article_assess/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '文章点评编辑'
    }
  }, {
    path: '/raw_supplier',
    name: 'raw_supplier',
    component: function component() {
      return import('@/pages/raw_supplier/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料供应商'
    }
  }, {
    path: '/raw_supplier/add',
    name: 'raw_supplier_add',
    component: function component() {
      return import('@/pages/raw_supplier/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料供应商添加'
    }
  }, {
    path: '/raw_supplier/edit',
    name: 'raw_supplier_edit',
    component: function component() {
      return import('@/pages/raw_supplier/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '原料供应商编辑'
    }
  }, {
    path: '/meal_accompany',
    name: 'meal_accompany',
    component: function component() {
      return import('@/pages/meal_accompany/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐管理'
    }
  }, {
    path: '/meal_accompany/add',
    name: 'meal_accompany_add',
    component: function component() {
      return import('@/pages/meal_accompany/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐添加'
    }
  }, {
    path: '/meal_accompany/edit',
    name: 'meal_accompany_edit',
    component: function component() {
      return import('@/pages/meal_accompany/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐编辑'
    }
  }, {
    path: '/meal_accompany_comment',
    name: 'meal_accompany_comment',
    component: function component() {
      return import('@/pages/meal_accompany_comment/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐评价管理'
    }
  }, {
    path: '/meal_accompany_comment/add',
    name: 'meal_accompany_comment_add',
    component: function component() {
      return import('@/pages/meal_accompany_comment/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐评价添加'
    }
  }, {
    path: '/meal_accompany_comment/edit',
    name: 'meal_accompany_comment_edit',
    component: function component() {
      return import('@/pages/meal_accompany_comment/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '配餐评价编辑'
    }
  }, {
    path: '/coach_check_date',
    name: 'coach_check_date',
    component: function component() {
      return import('@/pages/coach_check_date/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '日历排课'
    }
  }, {
    path: '/sign',
    name: 'sign',
    component: function component() {
      return import('@/pages/sign/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤地点管理'
    }
  }, {
    path: '/sign/add',
    name: 'sign_add',
    component: function component() {
      return import('@/pages/sign/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤地点添加'
    }
  }, {
    path: '/sign/edit',
    name: 'sign_edit',
    component: function component() {
      return import('@/pages/sign/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '考勤地点编辑'
    }
  }, {
    path: '/staff_check_reason',
    name: 'staff_check_reason',
    component: function component() {
      return import('@/pages/staff_check_reason/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '申诉原因分类'
    }
  }, {
    path: '/staff_check_reason/add',
    name: 'staff_check_reason_add',
    component: function component() {
      return import('@/pages/staff_check_reason/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '申诉原因分类添加'
    }
  }, {
    path: '/staff_check_reason/edit',
    name: 'staff_check_reason_edit',
    component: function component() {
      return import('@/pages/staff_check_reason/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '申诉原因分类编辑'
    }
  }, {
    path: '/staff_check_appeal',
    name: 'staff_check_appeal',
    component: function component() {
      return import('@/pages/staff_check_appeal/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '异常申诉'
    }
  }, {
    path: '/staff_check_appeal/detail',
    name: 'staff_check_appeal_detail',
    component: function component() {
      return import('@/pages/staff_check_appeal/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '异常申诉查询'
    }
  }, {
    path: '/course_monitor/index',
    name: 'course_monitor_index',
    component: function component() {
      return import('@/pages/course_monitor/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程监察'
    }
  }, {
    path: '/course_monitor/edit',
    name: 'course_monitor_edit',
    component: function component() {
      return import('@/pages/course_monitor/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课程检查编辑'
    }
  }, {
    path: '/order/receipt',
    name: 'order_receipt',
    component: function component() {
      return import('@/pages/order/receipt');
    },
    meta: {
      auth: true,
      cache: true,
      title: '收据'
    }
  }, {
    path: '/order/list_add',
    name: 'order_list_add',
    component: function component() {
      return import('@/pages/order/list_add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '批量下单'
    }
  }, {
    path: '/home_banner/index',
    name: 'home_banner',
    component: function component() {
      return import('@/pages/home_banner/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页Banner管理'
    }
  }, {
    path: '/home_banner/add',
    name: 'home_banner_add',
    component: function component() {
      return import('@/pages/home_banner/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页Banner添加'
    }
  }, {
    path: '/home_banner/edit',
    name: 'home_banner_edit',
    component: function component() {
      return import('@/pages/home_banner/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页Banner编辑'
    }
  }, {
    path: '/home_article_type/index',
    name: 'home_article_type',
    component: function component() {
      return import('@/pages/home_article_type/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章分类管理'
    }
  }, {
    path: '/home_article_type/add',
    name: 'home_article_type_add',
    component: function component() {
      return import('@/pages/home_article_type/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章分类添加'
    }
  }, {
    path: '/home_article_type/edit',
    name: 'home_article_type_edit',
    component: function component() {
      return import('@/pages/home_article_type/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章分类编辑'
    }
  }, {
    path: '/home_article/index',
    name: 'home_article',
    component: function component() {
      return import('@/pages/home_article/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章管理'
    }
  }, {
    path: '/home_article/add',
    name: 'home_article_add',
    component: function component() {
      return import('@/pages/home_article/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章添加'
    }
  }, {
    path: '/home_article/edit',
    name: 'home_article_edit',
    component: function component() {
      return import('@/pages/home_article/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页文章编辑'
    }
  }, {
    path: '/advert_setting',
    name: 'advert_setting',
    component: function component() {
      return import('@/pages/advert/setting');
    },
    meta: {
      auth: true,
      cache: true,
      title: '首页配置设置'
    }
  }, {
    path: '/orderAddList',
    name: 'orderAddList',
    component: function component() {
      return import('@/pages/order/orderAddList');
    },
    meta: {
      auth: true,
      cache: true,
      title: '批量订单详情'
    }
  }, {
    path: '/order_receipt_list',
    name: 'order_receipt_list',
    component: function component() {
      return import('@/pages/order/receipt_list');
    },
    meta: {
      auth: true,
      cache: true,
      title: '收据列表'
    }
  }, {
    path: '/supervise',
    name: 'supervise',
    component: function component() {
      return import('@/pages/supervise/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '监管内容'
    }
  }, {
    path: '/abarbeitung',
    name: 'abarbeitung',
    component: function component() {
      return import('@/pages/abarbeitung/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '整改建议'
    }
  }, {
    path: '/abarbeitung/dispose',
    name: 'abarbeitung_dispose',
    component: function component() {
      return import('@/pages/abarbeitung/dispose');
    },
    meta: {
      auth: true,
      cache: true,
      title: '整改建议详情'
    }
  }, {
    path: '/refund_money_setting',
    name: 'refund_money_setting',
    component: function component() {
      return import('@/pages/refund_money_setting/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '退费设置'
    }
  }, {
    path: '/refund_money_setting/save',
    name: 'refund_money_setting_save',
    component: function component() {
      return import('@/pages/refund_money_setting/save');
    },
    meta: {
      auth: true,
      cache: true,
      title: '退费设置编辑'
    }
  },
  //班次管理
  {
    path: '/sign_frequency_index',
    name: 'sign_frequency',
    component: function component() {
      return import('@/pages/sign_frequency/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '班次管理'
    }
  },
  //添加班次
  {
    path: '/sign_frequency_add',
    name: 'sign_frequency_add',
    component: function component() {
      return import('@/pages/sign_frequency/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '添加班次'
    }
  },
  //编辑班次
  {
    path: '/sign_frequency_edit',
    name: 'sign_frequency_edit',
    component: function component() {
      return import('@/pages/sign_frequency/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '编辑班次'
    }
  },
  //排班设置
  {
    path: '/sign_scheduling_save',
    name: 'sign_scheduling_save',
    component: function component() {
      return import('@/pages/sign_scheduling/save');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班设置'
    }
  },
  //排班设置
  {
    path: '/sign_scheduling_add',
    name: 'sign_scheduling_add',
    component: function component() {
      return import('@/pages/sign_scheduling/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '设置排班'
    }
  },
  //排班编辑
  {
    path: '/sign_scheduling_change',
    name: 'sign_scheduling_change',
    component: function component() {
      return import('@/pages/sign_scheduling/change');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班编辑'
    }
  },
  //排班记录表
  {
    path: '/sign_scheduling_index',
    name: 'sign_scheduling_index',
    component: function component() {
      return import('@/pages/sign_scheduling/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班管理'
    }
  },
  //排班记录表
  {
    path: '/sign_scheduling_edit',
    name: 'sign_scheduling_edit',
    component: function component() {
      return import('@/pages/sign_scheduling/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排班记录表编辑'
    }
  }, {
    path: '/withdraw',
    name: 'withdraw_index',
    component: function component() {
      return import('@/pages/withdraw/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '提现审核'
    }
  }, {
    path: '/withdraw/edit',
    name: 'withdraw_edit',
    component: function component() {
      return import('@/pages/withdraw/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '审核编辑'
    }
  }, {
    path: '/teacher_withdraw',
    name: 'teacher_withdraw_index',
    component: function component() {
      return import('@/pages/withdraw/teacher_index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '提现审核'
    }
  }, {
    path: '/teacher_withdraw/edit',
    name: 'teacher_withdraw_edit',
    component: function component() {
      return import('@/pages/withdraw/teacher_edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '审核编辑'
    }
  }, {
    path: '/course_cost',
    name: 'course_cost',
    component: function component() {
      return import('@/pages/course_cost/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课酬管理'
    }
  }, {
    path: '/course_cost/edit',
    name: 'course_cost_edit',
    component: function component() {
      return import('@/pages/course_cost/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课酬编辑'
    }
  }, {
    path: '/course_cost/list_edit',
    name: 'course_cost_list_edit',
    component: function component() {
      return import('@/pages/course_cost/list_edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课酬批量编辑'
    }
  }, {
    path: '/withdraw_refund',
    name: 'withdraw_refund',
    component: function component() {
      return import('@/pages/withdraw_refund/index');
    },
    meta: {
      auth: true,
      cache: true,
      title: '批量退款'
    }
  }, {
    path: '/course_scheduling',
    name: 'course_scheduling',
    component: function component() {
      return import('@/pages/course_scheduling/index');
    },
    meta: {
      auth: true,
      title: '排课计划'
    }
  }, {
    path: '/course_scheduling/add',
    name: 'course_scheduling_add',
    component: function component() {
      return import('@/pages/course_scheduling/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增排课'
    }
  }, {
    path: '/course_scheduling/detail',
    name: 'course_scheduling_detail',
    component: function component() {
      return import('@/pages/course_scheduling/detail');
    },
    meta: {
      auth: true,
      cache: true,
      title: '排课详情'
    }
  }, {
    path: '/course_scheduling/addNew',
    name: 'course_scheduling_addNew',
    component: function component() {
      return import('@/pages/course_scheduling/addNew');
    },
    meta: {
      auth: true,
      cache: true,
      title: '新增排课'
    }
  }, {
    path: '/course_scheduling/edit',
    name: 'course_scheduling_edit',
    component: function component() {
      return import('@/pages/course_scheduling/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '修改排课'
    }
  }, {
    path: '/class_number',
    name: 'class_number',
    component: function component() {
      return import('@/pages/class_number/index');
    },
    meta: {
      auth: true,
      title: '课节管理'
    }
  }, {
    path: '/class_number/add',
    name: 'class_number_add',
    component: function component() {
      return import('@/pages/class_number/add');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课节添加'
    }
  }, {
    path: '/class_number/edit',
    name: 'class_number_edit',
    component: function component() {
      return import('@/pages/class_number/edit');
    },
    meta: {
      auth: true,
      cache: true,
      title: '课节编辑'
    }
  }])
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);